<template>
  <div class="hourSchedule">
    <div class="hourScheduleContent">
      <table w100 v-if="schedule">
        <tr v-for="(day, index) in schedule" :key="index" v-html="`<td>${day.day}</td><td>${!day.hours.from ? `${$locale['closed']}` : `${day.hours.from} - ${day.hours.to}`}</td>`"></tr>
      </table>
      <div class="hourScheduleFooter third-font">Miami, FL</div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    schedule: function() {
      return [
        { day: this.$locale["monday"], hours: { from: "9:00 AM", to: "6:00 PM" } },
        { day: this.$locale["tuesday"], hours: { from: "9:00 AM", to: "6:00 PM" } },
        { day: this.$locale["wednesday"], hours: { from: "9:00 AM", to: "6:00 PM" } },
        { day: this.$locale["thursday"], hours: { from: "9:00 AM", to: "6:00 PM" } },
        { day: this.$locale["friday"], hours: { from: "9:00 AM", to: "6:00 PM" } },
        { day: this.$locale["saturday"], hours: { from: "9:00 AM", to: "12:00 PM" } },
        { day: this.$locale["sunday"], hours: { from: "", to: "" } },
      ];
    },
  },
};
</script>

<style lang="scss">
.hourSchedule {
  table tr td {
    padding: $mpadding 0;
  }
  table tr td:not(:first-child) {
    text-align: right;
    padding: 0 0 0 $mpadding;
  }
  .hourScheduleFooter {
    padding: $mpadding 0;
    border-top: solid 1px $alto;
    font-weight: 600;
  }
  .hourScheduleContent {
    border-top: solid 1px $alto;
    max-width: $tablet_width;
  }
}
</style>
